/**
 * Generates `nBytes` of random bytes.
 */
export function randomBytes(nBytes: number): Uint8Array {
    if (typeof crypto.getRandomValues === 'function') {
        const bytes = new Uint8Array(nBytes);
        crypto.getRandomValues(bytes);
        return bytes;
    }

    throw new Error('Unable to generate random bytes in this environment.');
}
