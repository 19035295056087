import { RevisionPageGroup, RevisionPageType } from '../types';
import { randomUUID } from '../utils';

/**
 * Create a page group.
 */
export function createPageGroup(
    parts: Pick<RevisionPageGroup, 'title'> & Partial<RevisionPageGroup>
): RevisionPageGroup {
    return {
        type: RevisionPageType.Group,
        id: randomUUID(),
        pages: [],
        edits: {},
        ...parts,
    };
}
