import { RevisionPageDocument, RevisionPageType } from '../types';
import { randomUUID } from '../utils';

export const DEFAULT_PAGE_TITLE = 'Page';

/**
 * Create a page document.
 */
export function createPageDocument(
    parts: Pick<RevisionPageDocument, 'title'> & Partial<RevisionPageDocument>
): RevisionPageDocument {
    return {
        type: RevisionPageType.Document,
        id: randomUUID(),
        pages: [],
        edits: {},
        ...parts,
    };
}

/**
 * Create an empty page Document.
 */
export function createEmptyPageDocument(title: string = DEFAULT_PAGE_TITLE): RevisionPageDocument {
    return createPageDocument({ title });
}
