import { randomBytes } from './randomBytes';

/**
 * Generate an unique ID.
 * Implementation taken from Firestore: https://github.com/firebase/firebase-js-sdk/blob/5ad7ff2ae955c297556223e6cb3ad9d4b897f664/packages/firestore/src/util/misc.ts#L28
 */
export function randomUUID(): string {
    // Alphanumeric characters
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    // The largest byte value that is a multiple of `char.length`.
    const maxMultiple = Math.floor(256 / chars.length) * chars.length;

    let autoId = '';
    const targetLength = 20;
    while (autoId.length < targetLength) {
        const bytes = randomBytes(40);
        for (let i = 0; i < bytes.length; ++i) {
            // Only accept values that are [0, maxMultiple), this ensures they can
            // be evenly mapped to indices of `chars` via a modulo operation.
            if (autoId.length < targetLength && bytes[i] < maxMultiple) {
                autoId += chars.charAt(bytes[i] % chars.length);
            }
        }
    }

    return autoId;
}
